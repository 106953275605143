import { mapHelper } from "@/utils/utils.js";
const statusMps = [
  {
    value: 0,
    text: "已提交，待审核",
  },
  {
    value: 1,
    text: "已完成积分获取",
  },
  {
    value: 2,
    text: "审核失败，请重新提交",
  },
];
const { map: setStatusMps, setOps: setStatusMpsOps } =
  mapHelper.setMap(statusMps);
export { statusMps, setStatusMps, setStatusMpsOps };
